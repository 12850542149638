<template>
  <div class="brands">
    <van-nav-bar
      title="品牌馆"
      left-text=""
      left-arrow
      @click-left="onClickLeft"
      safe-area-inset-top
      :border="false"
    />
    <van-skeleton title :row="6" :loading="loading">
      <div class="content-main">
        <van-grid :column-num="3" :border="false">
          <van-grid-item v-for="(item, index) in brands" :key="index">
            <template #default>
              <div @click="handleLinkToSearch(item)" class="brand-box">
                <img class="brand-pic" :src="item.logoUrl" />
                <div class="brand-name">{{ item.brandName }}</div>
              </div>
            </template>
          </van-grid-item>
        </van-grid>
      </div>
    </van-skeleton>
    <BottomTabbar />
  </div>
</template>

<script setup>
import { Toast } from "vant";
import { computed, ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getShopBrandList } from "../../api/common";
import BottomTabbar from "../../components/bottomTabbar.vue";
const brands = ref([]);
const router = useRouter();
const store = useStore();
const token = computed(() => store.getters.token);
const materialId = computed(() => store.getters.materialId);
const merchantId = computed(() => store.getters.merchantId);
const loading = ref(true);
const onClickLeft = () => {
  router.back();
};
const handleLinkToSearch = (item) => {
  console.log("item=======", item);
  router.push({ name: "goodsSearch", query: { search: item.brandName } });
};
const loadBrandList = async () => {
  Toast.loading({ duration: 300, message: "加载中" });
  loading.value = true;
  brands.value = await getShopBrandList({
    token: token.value,
    materialId: materialId.value,
  });
  Toast.clear();
  loading.value = false;
};
loadBrandList();
</script>

<style lang="scss" scoped>
.brands {
  .content-main {
    margin-bottom: 50px;
  }
  .brand-box {
    .brand-pic {
      width: 100px;
      height: 100px;
      border-radius: 50%;
      object-fit: cover;
    }
    .brand-name {
      color: #696974;
      font-size: 14px;
      margin-top: 16px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      width: 100px;
    }
  }
}
</style>
<style lang="scss">
.brands {
  .van-grid-item__content {
    background: #fff;
  }
}
</style>
